<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/demo/video/index.vue
 * @Author: 张兴业
 * @Date: 2021-06-03 16:50:14
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-06-03 17:06:36
-->
<template>
  <div>
    <video id="videoElement" controls autoplay muted width="100%" />
    <button @click="play">播放</button>
  </div>
</template>

<script>
import flvjs from "flv.js";

export default {
  data() {
    return {
      flvPlayer: null
    };
  },
  mounted() {
    if (flvjs.isSupported()) {
      var videoElement = document.getElementById("videoElement");
      this.flvPlayer = flvjs.createPlayer({
        type: "flv",
        isLive: true,
        hasAudio: false,
        url: "https://liteavapp.qcloud.com/live/liteavdemoplayerstreamid.flv"
      });
      this.flvPlayer.attachMediaElement(videoElement);
      this.flvPlayer.load();
      this.flvPlayer.play();
    }
  },
  methods: {
    play() {
      this.flvPlayer.play();
    }
  }
};
</script>
